import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"

import { shadows, borders, colors } from "../styles"

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  color: black;
  background-color: white;
  border: ${borders.standard};
  border-radius: 0;
  box-shadow: ${shadows.standard};
  overflow: hidden;

  @media screen and (min-width: 768px) {
    border-radius: 0.5rem;
  }
`

const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1rem;
`

const InputContainer = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
`

const InputLabel = styled.span`
  margin-right: 0.5rem;

  @media screen and (min-width: 768px) {
    min-width: 4rem;
  }
`

const Input = styled.input`
  font-family: "Open Sans", sans-serif;
  font-size: 0.75rem;
  width: 100%;
  min-height: 1.5rem;
  border: solid 1px darkgrey;
  border-radius: 6px;
  margin-right: 1rem;
  margin-top: 0.5rem;

  @media screen and (min-width: 768px) {
    min-width: 10rem;
  }
`

const Dropdown = styled.select`
  font-family: "Open Sans", sans-serif;
  font-size: 0.75rem;
  padding: 0.25rem 0;
  margin-top: 0.5rem;

  @media screen and (min-width: 768px) {
    min-width: 7.5rem;
  }
`

const MessageField = styled.textarea`
  font-family: "Open Sans", sans-serif;
  font-size: 0.75rem;
  border: solid 1px darkgrey;
  border-radius: 6px;
  margin-bottom: 1.5rem;
`

const Button = styled.button`
  cursor: pointer;
  transition: all 0.5s ease;
  border: none;
  border-radius: 0.5rem;
  background: ${colors.primary};
  color: white;
  padding: 0.75rem 2rem;
  margin-top: 0.75rem;

  &:hover {
    font-size: 1.1rem;
  }

  &:disabled {
    background: ${colors.primaryLight};
    font-style: italic;
  }

  @media screen and (min-width: 768px) {
    margin-right: auto;
  }
`

const ContactUsForm = () => {
  const [isSuccessful, setSuccessful] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (isSuccessful) {
      setTimeout(() => setSuccessful(false), 5000)
    }
  }, [isSuccessful])

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    setSuccessful(false)
    setSubmitting(true)

    const keys = ["Name", "Phone", "Email", "Gender", "Lesson type", "Message"]
    const fields = {}
    keys.forEach((key) => {
      const elem = document.getElementById(key)
      const isSelect = elem instanceof HTMLSelectElement

      let value
      if (isSelect) {
        value = elem.options[elem.selectedIndex].value
      } else {
        value = elem.value
      }

      fields[key] = value
    })

    await fetch("/.netlify/functions/contact", {
      method: "POST",
      body: JSON.stringify(fields),
      headers: { "Content-Type": "application/json" },
    }).then((res) => res.json())

    document.getElementById("contact-form").reset()

    setSuccessful(true)
    setSubmitting(false)
  }

  return (
    <Form id="contact-form">
      <h2>Otherwise, send us a message below to book</h2>
      {isSuccessful && <span>Message sent successfully.</span>}
      <InputsContainer>
        <InputContainer>
          <InputLabel>Name</InputLabel>{" "}
          <Input type="text" name="name" id="Name" required />
        </InputContainer>
        <InputContainer>
          <InputLabel>Phone</InputLabel>{" "}
          <Input type="tel" name="tel" id="Phone" required />
        </InputContainer>
        <InputContainer>
          <InputLabel>Email</InputLabel>{" "}
          <Input type="email" name="email" id="Email" required />
        </InputContainer>
        <InputContainer style={{ marginTop: "1rem" }}>
          <InputLabel>Gender</InputLabel>
          <Dropdown name="gender" defaultValue="female" id="Gender">
            <option value="female">Female</option>
            <option value="male">Male</option>
            <option value="na">Undisclosed</option>
          </Dropdown>
          <InputLabel style={{ marginLeft: "0.5rem" }}>Lesson type</InputLabel>
          <Dropdown
            id="Lesson type"
            name="lesson-type"
            defaultValue="voice"
            style={{ marginLeft: "0.5rem" }}
          >
            <option value="voice">Voice</option>
            <option value="piano">Piano</option>
          </Dropdown>
        </InputContainer>
      </InputsContainer>
      <br />
      Message
      <br />
      <MessageField id="Message" rows="10" name="message" required />
      <Button type="submit" disabled={isSubmitting} onClick={handleFormSubmit}>
        {isSubmitting ? "Sending..." : "Send"}
      </Button>
      {isSuccessful && <span>Message sent successfully.</span>}
    </Form>
  )
}

export default ContactUsForm
