import React from "react"
import styled from "@emotion/styled"
import { FaFacebookMessenger } from "react-icons/fa"

import Seo from "../components/seo"
import Layout from "../components/layout"
import { colors, shadows, borders } from "../styles"

import ContactUsForm from "../components/contact-us-form"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 50rem;
  gap: 4rem;
`

const MessengerInfoCard = styled.div`
  padding: 1rem 2rem;
  border: ${borders.standard};
  border-radius: 0;
  background-color: ${colors.primary};
  box-shadow: ${shadows.standard};

  @media screen and (min-width: 768px) {
    border-radius: 0.5rem;
  }
`

const OpenMessengerButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  font-size: 2rem;
  color: royalblue;
  background: white;
  border: ${borders.standard};
  border-radius: 0.5rem;

  span {
    padding: 0 1rem;
    font-size: 1.25rem;
    font-weight: bold;
  }

  &:hover {
    filter: opacity(0.9);
  }
`

const TermsBlurb = styled.div`
  padding: 0 1rem;
  margin-bottom: 2rem;
  font-size: 0.75rem;

  @media screen and (min-width: 768px) {
    padding: 0;
  }
`

const ContactPage = () => {
  const openChat = () => window.FB.CustomerChat.showDialog()

  return (
    <Layout>
      <Seo
        title="Contact us | Sing Smart Studios | Townsville Singing School"
        keywords={[`contact`, `book`, `booking`, `email`]}
      />

      <Container>
        <MessengerInfoCard>
          <h1>Get in touch directly</h1>
          <p>
            Your <strong>best and fastest way</strong> to find more information
            or book lessons is to message us directly through Facebook.
          </p>
          <OpenMessengerButton onClick={openChat}>
            <FaFacebookMessenger />
            <span>Chat now</span>
          </OpenMessengerButton>
          <p>We look forward to hearing from you soon.</p>
        </MessengerInfoCard>

        <ContactUsForm />

        <TermsBlurb>
          <p>
            Sing Smart Studios follows the terms set by the Queensland public
            school system. Prior to your first singing lesson you will be
            invoiced for the total cost of the term. Lessons can only commence
            once term fees are paid in full.
          </p>
          <p>
            <strong>Payment plans: </strong>Payment plans are available only if
            arranged in advance.
          </p>
          <p>
            <strong>NB: </strong>Every effort must be made to attend scheduled
            singing lessons as make up lessons and refunds are not offered.
          </p>
        </TermsBlurb>
      </Container>
    </Layout>
  )
}

export default ContactPage
